export const TimezoneAndManagingLocales = [
  { timezone: "Africa/Abidjan", locale: "fr-CI" },
  { timezone: "Africa/Accra", locale: "en-GH" },
  { timezone: "Africa/Addis_Ababa", locale: "am-ET" },
  { timezone: "Africa/Algiers", locale: "ar-DZ" },
  { timezone: "Africa/Asmera", locale: "en-ER" },
  { timezone: "Africa/Bamako", locale: "fr-ML" },
  { timezone: "Africa/Bangui", locale: "fr-CF" },
  { timezone: "Africa/Banjul", locale: "en-GM" },
  { timezone: "Africa/Bissau", locale: "pt-GW" },
  { timezone: "Africa/Blantyre", locale: "en-MW" },
  { timezone: "Africa/Brazzaville", locale: "fr-CG" },
  { timezone: "Africa/Bujumbura", locale: "fr-BI" },
  { timezone: "Africa/Cairo", locale: "ar-EG" },
  { timezone: "Africa/Casablanca", locale: "ar-MA" },
  { timezone: "Africa/Ceuta", locale: "es-ES" },
  { timezone: "Africa/Conakry", locale: "fr-GN" },
  { timezone: "Africa/Dakar", locale: "fr-SN" },
  { timezone: "Africa/Dar_es_Salaam", locale: "en-TZ" },
  { timezone: "Africa/Djibouti", locale: "ar-DJ" },
  { timezone: "Africa/Douala", locale: "en-CM" },
  { timezone: "Africa/El_Aaiun", locale: "es-EH" },
  { timezone: "Africa/Freetown", locale: "en-SL" },
  { timezone: "Africa/Gaborone", locale: "en-BW" },
  { timezone: "Africa/Harare", locale: "en-ZW" },
  { timezone: "Africa/Johannesburg", locale: "en-ZA" },
  { timezone: "Africa/Juba", locale: "en-SS" },
  { timezone: "Africa/Kampala", locale: "en-UG" },
  { timezone: "Africa/Khartoum", locale: "en-SD" },
  { timezone: "Africa/Kigali", locale: "en-RW" },
  { timezone: "Africa/Kinshasa", locale: "fr-CD" },
  { timezone: "Africa/Lagos", locale: "en-NG" },
  { timezone: "Africa/Libreville", locale: "fr-GA" },
  { timezone: "Africa/Lome", locale: "fr-TG" },
  { timezone: "Africa/Luanda", locale: "pt-AO" },
  { timezone: "Africa/Lubumbashi", locale: "fr-CD" },
  { timezone: "Africa/Lusaka", locale: "en-ZM" },
  { timezone: "Africa/Malabo", locale: "es-GQ" },
  { timezone: "Africa/Maputo", locale: "pt-MZ" },
  { timezone: "Africa/Maseru", locale: "en-LS" },
  { timezone: "Africa/Mbabane", locale: "en-SZ" },
  { timezone: "Africa/Mogadishu", locale: "ar-SO" },
  { timezone: "Africa/Monrovia", locale: "en-LR" },
  { timezone: "Africa/Nairobi", locale: "en-KE" },
  { timezone: "Africa/Ndjamena", locale: "fr-TD" },
  { timezone: "Africa/Niamey", locale: "fr-NE" },
  { timezone: "Africa/Nouakchott", locale: "ar-MR" },
  { timezone: "Africa/Ouagadougou", locale: "fr-BF" },
  { timezone: "Africa/Porto-Novo", locale: "fr-BJ" },
  { timezone: "Africa/Sao_Tome", locale: "pt-ST" },
  { timezone: "Africa/Tripoli", locale: "ar-LY" },
  { timezone: "Africa/Tunis", locale: "ar-TN" },
  { timezone: "Africa/Windhoek", locale: "en-NA" },
  { timezone: "America/Adak", locale: "en-US" },
  { timezone: "America/Anchorage", locale: "en-US" },
  { timezone: "America/Anguilla", locale: "en-AI" },
  { timezone: "America/Antigua", locale: "en-AG" },
  { timezone: "America/Araguaina", locale: "pt-BR" },
  { timezone: "America/Argentina/La_Rioja", locale: "es-AR" },
  { timezone: "America/Argentina/Rio_Gallegos", locale: "es-AR" },
  { timezone: "America/Argentina/Salta", locale: "es-AR" },
  { timezone: "America/Argentina/San_Juan", locale: "es-AR" },
  { timezone: "America/Argentina/San_Luis", locale: "es-AR" },
  { timezone: "America/Argentina/Tucuman", locale: "es-AR" },
  { timezone: "America/Argentina/Ushuaia", locale: "es-AR" },
  { timezone: "America/Aruba", locale: "nl-AW" },
  { timezone: "America/Asuncion", locale: "es-PY" },
  { timezone: "America/Bahia", locale: "pt-BR" },
  { timezone: "America/Bahia_Banderas", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Barbados", locale: "en-BB" },
  { timezone: "America/Belem", locale: "pt-BR" },
  { timezone: "America/Belize", locale: "en-BZ" },
  { timezone: "America/Blanc-Sablon", locale: "en-CA" },
  { timezone: "America/Boa_Vista", locale: "pt-BR" },
  { timezone: "America/Bogota", locale: "es-CO" },
  { timezone: "America/Boise", locale: "en-US" },
  { timezone: "America/Buenos_Aires", locale: "es-AR" },
  { timezone: "America/Cambridge_Bay", locale: "en-CA" },
  { timezone: "America/Campo_Grande", locale: "pt-BR" },
  { timezone: "America/Cancun", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Caracas", locale: "es-VE" },
  { timezone: "America/Catamarca", locale: "es-AR" },
  { timezone: "America/Cayenne", locale: "fr-GF" },
  { timezone: "America/Cayman", locale: "en-KY" },
  { timezone: "America/Chicago", locale: "en-US" },
  { timezone: "America/Chihuahua", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Ciudad_Juarez", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Coral_Harbour", locale: "en-CA" },
  { timezone: "America/Cordoba", locale: "es-AR" },
  { timezone: "America/Costa_Rica", locale: "es-CR" },
  { timezone: "America/Creston", locale: "en-CA" },
  { timezone: "America/Cuiaba", locale: "pt-BR" },
  { timezone: "America/Curacao", locale: "en-CW" },
  { timezone: "America/Danmarkshavn", locale: "kl-GL" },
  { timezone: "America/Dawson", locale: "en-CA" },
  { timezone: "America/Dawson_Creek", locale: "en-CA" },
  { timezone: "America/Denver", locale: "en-US" },
  { timezone: "America/Detroit", locale: "en-US" },
  { timezone: "America/Dominica", locale: "en-DM" },
  { timezone: "America/Edmonton", locale: "en-CA" },
  { timezone: "America/Eirunepe", locale: "pt-BR" },
  { timezone: "America/El_Salvador", locale: "es-SV" },
  { timezone: "America/Fort_Nelson", locale: "en-CA" },
  { timezone: "America/Fortaleza", locale: "pt-BR" },
  { timezone: "America/Glace_Bay", locale: "en-CA" },
  { timezone: "America/Godthab", locale: "kl-GL" },
  { timezone: "America/Goose_Bay", locale: "en-CA" },
  { timezone: "America/Grand_Turk", locale: "en-TC" },
  { timezone: "America/Grenada", locale: "en-GD" },
  { timezone: "America/Guadeloupe", locale: "fr-GP" },
  { timezone: "America/Guatemala", locale: "es-GT" },
  { timezone: "America/Guayaquil", locale: "es-EC" },
  { timezone: "America/Guyana", locale: "en-GY" },
  { timezone: "America/Halifax", locale: "en-CA" },
  { timezone: "America/Havana", locale: "es-CU" },
  { timezone: "America/Hermosillo", locale: "es-MX" }, //This one doesn't seem to be working even using "en" like the other Mexican timezones, not sure about it
  { timezone: "America/Indiana/Knox", locale: "en-US" },
  { timezone: "America/Indiana/Marengo", locale: "en-US" },
  { timezone: "America/Indiana/Petersburg", locale: "en-US" },
  { timezone: "America/Indiana/Tell_City", locale: "en-US" },
  { timezone: "America/Indiana/Vevay", locale: "en-US" },
  { timezone: "America/Indiana/Vincennes", locale: "en-US" },
  { timezone: "America/Indiana/Winamac", locale: "en-US" },
  { timezone: "America/Indianapolis", locale: "en-US" },
  { timezone: "America/Inuvik", locale: "en-CA" },
  { timezone: "America/Iqaluit", locale: "en-CA" },
  { timezone: "America/Jamaica", locale: "en-JM" },
  { timezone: "America/Jujuy", locale: "es-AR" },
  { timezone: "America/Juneau", locale: "en-US" },
  { timezone: "America/Kentucky/Monticello", locale: "en-US" },
  { timezone: "America/Kralendijk", locale: "nl-BQ" },
  { timezone: "America/La_Paz", locale: "es-BO" },
  { timezone: "America/Lima", locale: "es-PE" },
  { timezone: "America/Los_Angeles", locale: "en-US" },
  { timezone: "America/Louisville", locale: "en-US" },
  { timezone: "America/Lower_Princes", locale: "en-SX" },
  { timezone: "America/Maceio", locale: "pt-BR" },
  { timezone: "America/Managua", locale: "es-NI" },
  { timezone: "America/Manaus", locale: "pt-BR" },
  { timezone: "America/Marigot", locale: "en-MF" },
  { timezone: "America/Martinique", locale: "fr-MQ" },
  { timezone: "America/Matamoros", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Mazatlan", locale: "es-MX" }, //This one doesn't seem to be working even using "en" like the other Mexican timezones, not sure about it
  { timezone: "America/Mendoza", locale: "es-AR" },
  { timezone: "America/Menominee", locale: "en-US" },
  { timezone: "America/Merida", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Metlakatla", locale: "en-US" },
  { timezone: "America/Mexico_City", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Miquelon", locale: "fr-PM" },
  { timezone: "America/Moncton", locale: "en-CA" },
  { timezone: "America/Monterrey", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Montevideo", locale: "es-UY" },
  { timezone: "America/Montserrat", locale: "en-MS" },
  { timezone: "America/Nassau", locale: "en-BS" },
  { timezone: "America/New_York", locale: "en-US" },
  { timezone: "America/Nome", locale: "en-US" },
  { timezone: "America/Noronha", locale: "pt-BR" },
  { timezone: "America/North_Dakota/Beulah", locale: "en-US" },
  { timezone: "America/North_Dakota/Center", locale: "en-US" },
  { timezone: "America/North_Dakota/New_Salem", locale: "en-US" },
  { timezone: "America/Ojinaga", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Panama", locale: "es-PA" },
  { timezone: "America/Paramaribo", locale: "nl-SR" },
  { timezone: "America/Phoenix", locale: "en-US" },
  { timezone: "America/Port_of_Spain", locale: "en-TT" },
  { timezone: "America/Port-au-Prince", locale: "fr-HT" },
  { timezone: "America/Porto_Velho", locale: "pt-BR" },
  { timezone: "America/Puerto_Rico", locale: "es-PR" },
  { timezone: "America/Punta_Arenas", locale: "es-CL" },
  { timezone: "America/Rankin_Inlet", locale: "en-CA" },
  { timezone: "America/Recife", locale: "pt-BR" },
  { timezone: "America/Regina", locale: "en-CA" },
  { timezone: "America/Resolute", locale: "en-CA" },
  { timezone: "America/Rio_Branco", locale: "pt-BR" },
  { timezone: "America/Santarem", locale: "pt-BR" },
  { timezone: "America/Santiago", locale: "es-CL" },
  { timezone: "America/Santo_Domingo", locale: "es-DO" },
  { timezone: "America/Sao_Paulo", locale: "pt-BR" },
  { timezone: "America/Scoresbysund", locale: "kl-GL" },
  { timezone: "America/Sitka", locale: "en-US" },
  { timezone: "America/St_Barthelemy", locale: "fr-BL" },
  { timezone: "America/St_Johns", locale: "en-CA" },
  { timezone: "America/St_Kitts", locale: "en-KN" },
  { timezone: "America/St_Lucia", locale: "en-LC" },
  { timezone: "America/St_Thomas", locale: "en-VI" },
  { timezone: "America/St_Vincent", locale: "en-VC" },
  { timezone: "America/Swift_Current", locale: "en-CA" },
  { timezone: "America/Tegucigalpa", locale: "es-HN" },
  { timezone: "America/Thule", locale: "kl-GL" },
  { timezone: "America/Tijuana", locale: "en" }, //This really should be "es-MX" but for some reason Intl.DateTimeFormat to get the short timezone only works with "en"
  { timezone: "America/Toronto", locale: "en-CA" },
  { timezone: "America/Tortola", locale: "en-VG" },
  { timezone: "America/Vancouver", locale: "en-CA" },
  { timezone: "America/Whitehorse", locale: "en-CA" },
  { timezone: "America/Winnipeg", locale: "en-CA" },
  { timezone: "America/Yakutat", locale: "en-US" },
  { timezone: "Antarctica/Casey", locale: "en-AQ" },
  { timezone: "Antarctica/Davis", locale: "en-AQ" },
  { timezone: "Antarctica/DumontDUrville", locale: "en-AQ" },
  { timezone: "Antarctica/Macquarie", locale: "en-AU" },
  { timezone: "Antarctica/Mawson", locale: "en-AQ" },
  { timezone: "Antarctica/McMurdo", locale: "en-AQ" },
  { timezone: "Antarctica/Palmer", locale: "en-AQ" },
  { timezone: "Antarctica/Rothera", locale: "en-AQ" },
  { timezone: "Antarctica/Syowa", locale: "en-AQ" },
  { timezone: "Antarctica/Troll", locale: "en-AQ" },
  { timezone: "Antarctica/Vostok", locale: "en-AQ" },
  { timezone: "Arctic/Longyearbyen", locale: "no-SJ" },
  { timezone: "Asia/Aden", locale: "ar-YE" },
  { timezone: "Asia/Almaty", locale: "kk-KZ" },
  { timezone: "Asia/Amman", locale: "ar-JO" },
  { timezone: "Asia/Anadyr", locale: "ru-RU" },
  { timezone: "Asia/Aqtau", locale: "kk-KZ" },
  { timezone: "Asia/Aqtobe", locale: "kk-KZ" },
  { timezone: "Asia/Ashgabat", locale: "tk-TM" },
  { timezone: "Asia/Atyrau", locale: "kk-KZ" },
  { timezone: "Asia/Baghdad", locale: "ar-IQ" },
  { timezone: "Asia/Bahrain", locale: "ar-BH" },
  { timezone: "Asia/Baku", locale: "az-AZ" },
  { timezone: "Asia/Bangkok", locale: "th-TH" },
  { timezone: "Asia/Barnaul", locale: "ru-RU" },
  { timezone: "Asia/Beirut", locale: "ar-LB" },
  { timezone: "Asia/Bishkek", locale: "ky-kg" },
  { timezone: "Asia/Brunei", locale: "ms-BN" },
  { timezone: "Asia/Calcutta", locale: "en-IN" },
  { timezone: "Asia/Chita", locale: "ru-RU" },
  { timezone: "Asia/Colombo", locale: "ta-LK" },
  { timezone: "Asia/Damascus", locale: "ar-SY" },
  { timezone: "Asia/Dhaka", locale: "bn-BD" },
  { timezone: "Asia/Dili", locale: "pt-TL" },
  { timezone: "Asia/Dubai", locale: "ar-AE" },
  { timezone: "Asia/Dushanbe", locale: "tg-TJ" },
  { timezone: "Asia/Famagusta", locale: "tr-CY" },
  { timezone: "Asia/Gaza", locale: "ar-PS" },
  { timezone: "Asia/Hebron", locale: "ar-PS" },
  { timezone: "Asia/Hong_Kong", locale: "en-HK" },
  { timezone: "Asia/Hovd", locale: "mn-MN" },
  { timezone: "Asia/Irkutsk", locale: "ru-RU" },
  { timezone: "Asia/Jakarta", locale: "id-ID" },
  { timezone: "Asia/Jayapura", locale: "id-ID" },
  { timezone: "Asia/Jerusalem", locale: "ar-IL" },
  { timezone: "Asia/Kabul", locale: "ps-AF" },
  { timezone: "Asia/Kamchatka", locale: "ru-RU" },
  { timezone: "Asia/Karachi", locale: "en-PK" },
  { timezone: "Asia/Katmandu", locale: "ne-NP" },
  { timezone: "Asia/Khandyga", locale: "ru-RU" },
  { timezone: "Asia/Krasnoyarsk", locale: "ru-RU" },
  { timezone: "Asia/Kuala_Lumpur", locale: "ms-MY" },
  { timezone: "Asia/Kuching", locale: "ms-MY" },
  { timezone: "Asia/Kuwait", locale: "ar-KW" },
  { timezone: "Asia/Macau", locale: "pt-MO" },
  { timezone: "Asia/Magadan", locale: "ru-RU" },
  { timezone: "Asia/Makassar", locale: "id-ID" },
  { timezone: "Asia/Manila", locale: "en-PH" },
  { timezone: "Asia/Muscat", locale: "ar-OM" },
  { timezone: "Asia/Nicosia", locale: "tr-CY" },
  { timezone: "Asia/Novokuznetsk", locale: "ru-RU" },
  { timezone: "Asia/Novosibirsk", locale: "ru-RU" },
  { timezone: "Asia/Omsk", locale: "ru-RU" },
  { timezone: "Asia/Oral", locale: "kk-KZ" },
  { timezone: "Asia/Phnom_Penh", locale: "km-KH" },
  { timezone: "Asia/Pontianak", locale: "id-ID" },
  { timezone: "Asia/Pyongyang", locale: "ko-KP" },
  { timezone: "Asia/Qatar", locale: "ar-QA" },
  { timezone: "Asia/Qostanay", locale: "kk-KZ" },
  { timezone: "Asia/Qyzylorda", locale: "kk-KZ" },
  { timezone: "Asia/Rangoon", locale: "my-MM" },
  { timezone: "Asia/Riyadh", locale: "ar-SA" },
  { timezone: "Asia/Saigon", locale: "vi-VN" },
  { timezone: "Asia/Sakhalin", locale: "ru-RU" },
  { timezone: "Asia/Samarkand", locale: "uz-UZ" },
  { timezone: "Asia/Seoul", locale: "ko-KR" },
  { timezone: "Asia/Shanghai", locale: "zh-CN" },
  { timezone: "Asia/Singapore", locale: "en-SG" },
  { timezone: "Asia/Srednekolymsk", locale: "ru-RU" },
  { timezone: "Asia/Taipei", locale: "zh-TW" },
  { timezone: "Asia/Tashkent", locale: "uz-UZ" },
  { timezone: "Asia/Tbilisi", locale: "ka-GE" },
  { timezone: "Asia/Tehran", locale: "fa-IR" },
  { timezone: "Asia/Thimphu", locale: "dz-BT" },
  { timezone: "Asia/Tokyo", locale: "ja-JP" },
  { timezone: "Asia/Tomsk", locale: "ru-RU" },
  { timezone: "Asia/Ulaanbaatar", locale: "mn-MN" },
  { timezone: "Asia/Urumqi", locale: "zh-CN" },
  { timezone: "Asia/Ust-Nera", locale: "ru-RU" },
  { timezone: "Asia/Vientiane", locale: "lo-LA" },
  { timezone: "Asia/Vladivostok", locale: "ru-RU" },
  { timezone: "Asia/Yakutsk", locale: "ru-RU" },
  { timezone: "Asia/Yekaterinburg", locale: "ru-RU" },
  { timezone: "Asia/Yerevan", locale: "hy-AM" },
  { timezone: "Atlantic/Azores", locale: "pt-PT" },
  { timezone: "Atlantic/Bermuda", locale: "en-BM" },
  { timezone: "Atlantic/Canary", locale: "es-ES" },
  { timezone: "Atlantic/Cape_Verde", locale: "pt-CV" },
  { timezone: "Atlantic/Faeroe", locale: "fo-FO" },
  { timezone: "Atlantic/Madeira", locale: "pt-PT" },
  { timezone: "Atlantic/Reykjavik", locale: "is-IS" },
  { timezone: "Atlantic/South_Georgia", locale: "en-GS" },
  { timezone: "Atlantic/St_Helena", locale: "en-SH" },
  { timezone: "Atlantic/Stanley", locale: "en-FK" },
  { timezone: "Australia/Adelaide", locale: "en-AU" },
  { timezone: "Australia/Brisbane", locale: "en-AU" },
  { timezone: "Australia/Broken_Hill", locale: "en-AU" },
  { timezone: "Australia/Darwin", locale: "en-AU" },
  { timezone: "Australia/Eucla", locale: "en-AU" },
  { timezone: "Australia/Hobart", locale: "en-AU" },
  { timezone: "Australia/Lindeman", locale: "en-AU" },
  { timezone: "Australia/Lord_Howe", locale: "en-AU" },
  { timezone: "Australia/Melbourne", locale: "en-AU" },
  { timezone: "Australia/Perth", locale: "en-AU" },
  { timezone: "Australia/Sydney", locale: "en-AU" },
  { timezone: "Europe/Amsterdam", locale: "nl-NL" },
  { timezone: "Europe/Andorra", locale: "ca-AD" },
  { timezone: "Europe/Astrakhan", locale: "ru-RU" },
  { timezone: "Europe/Athens", locale: "el-GR" },
  { timezone: "Europe/Belgrade", locale: "sr-RS" },
  { timezone: "Europe/Berlin", locale: "de-DE" },
  { timezone: "Europe/Bratislava", locale: "sk-SK" },
  { timezone: "Europe/Brussels", locale: "fe-BE" },
  { timezone: "Europe/Bucharest", locale: "ro-RO" },
  { timezone: "Europe/Budapest", locale: "hu-HU" },
  { timezone: "Europe/Busingen", locale: "de-DE" },
  { timezone: "Europe/Chisinau", locale: "ro-MD" },
  { timezone: "Europe/Copenhagen", locale: "da-DK" },
  { timezone: "Europe/Dublin", locale: "en-IE" },
  { timezone: "Europe/Gibraltar", locale: "en-GI" },
  { timezone: "Europe/Guernsey", locale: "en-GG" },
  { timezone: "Europe/Helsinki", locale: "fi-FI" },
  { timezone: "Europe/Isle_of_Man", locale: "en-IM" },
  { timezone: "Europe/Istanbul", locale: "tr-TR" },
  { timezone: "Europe/Jersey", locale: "en-JE" },
  { timezone: "Europe/Kaliningrad", locale: "ru-RU" },
  { timezone: "Europe/Kiev", locale: "uk-UA" },
  { timezone: "Europe/Kirov", locale: "ru-RU" },
  { timezone: "Europe/Lisbon", locale: "pt-PT" },
  { timezone: "Europe/Ljubljana", locale: "sl-SI" },
  { timezone: "Europe/London", locale: "en-GB" },
  { timezone: "Europe/Luxembourg", locale: "de-LU" },
  { timezone: "Europe/Madrid", locale: "es-ES" },
  { timezone: "Europe/Malta", locale: "en-MT" },
  { timezone: "Europe/Mariehamn", locale: "sv-AX" },
  { timezone: "Europe/Minsk", locale: "be-BY" },
  { timezone: "Europe/Monaco", locale: "fr-MC" },
  { timezone: "Europe/Moscow", locale: "ru-RU" },
  { timezone: "Europe/Oslo", locale: "no-NO" },
  { timezone: "Europe/Paris", locale: "fr-FR" },
  { timezone: "Europe/Podgorica", locale: "sr-ME" },
  { timezone: "Europe/Prague", locale: "sk-CZ" },
  { timezone: "Europe/Riga", locale: "lv-LV" },
  { timezone: "Europe/Rome", locale: "it-IT" },
  { timezone: "Europe/Samara", locale: "ru-RU" },
  { timezone: "Europe/San_Marino", locale: "it-SM" },
  { timezone: "Europe/Sarajevo", locale: "sr-BA" },
  { timezone: "Europe/Saratov", locale: "ru-RU" },
  { timezone: "Europe/Simferopol", locale: "uk-UA" },
  { timezone: "Europe/Skopje", locale: "mk-MK" },
  { timezone: "Europe/Sofia", locale: "bg-BG" },
  { timezone: "Europe/Stockholm", locale: "sv-SE" },
  { timezone: "Europe/Tallinn", locale: "et-EE" },
  { timezone: "Europe/Tirane", locale: "sq-AL" },
  { timezone: "Europe/Ulyanovsk", locale: "ru-RU" },
  { timezone: "Europe/Vaduz", locale: "de-LI" },
  { timezone: "Europe/Vatican", locale: "it-VA" },
  { timezone: "Europe/Vienna", locale: "de-AT" },
  { timezone: "Europe/Vilnius", locale: "lt-LT" },
  { timezone: "Europe/Volgograd", locale: "ru-RU" },
  { timezone: "Europe/Warsaw", locale: "pl-PL" },
  { timezone: "Europe/Zagreb", locale: "hr-HR" },
  { timezone: "Europe/Zurich", locale: "de-CH" },
  { timezone: "Indian/Antananarivo", locale: "fr-MG" },
  { timezone: "Indian/Chagos", locale: "en-IO" },
  { timezone: "Indian/Christmas", locale: "en-CX" },
  { timezone: "Indian/Cocos", locale: "en-CC" },
  { timezone: "Indian/Comoro", locale: "fr-KM" },
  { timezone: "Indian/Kerguelen", locale: "fr-TF" },
  { timezone: "Indian/Mahe", locale: "en-SC" },
  { timezone: "Indian/Maldives", locale: "dv-MV" },
  { timezone: "Indian/Mauritius", locale: "en-MU" },
  { timezone: "Indian/Mayotte", locale: "fr-YT" },
  { timezone: "Indian/Reunion", locale: "fr-RE" },
  { timezone: "Pacific/Apia", locale: "en-WS" },
  { timezone: "Pacific/Auckland", locale: "en-NZ" },
  { timezone: "Pacific/Bougainville", locale: "en-PG" },
  { timezone: "Pacific/Chatham", locale: "en-NZ" },
  { timezone: "Pacific/Easter", locale: "es-CL" },
  { timezone: "Pacific/Efate", locale: "en-VU" },
  { timezone: "Pacific/Enderbury", locale: "en-KI" },
  { timezone: "Pacific/Fakaofo", locale: "en-TK" },
  { timezone: "Pacific/Fiji", locale: "en-FJ" },
  { timezone: "Pacific/Funafuti", locale: "en-TV" },
  { timezone: "Pacific/Galapagos", locale: "es-EC" },
  { timezone: "Pacific/Gambier", locale: "fr-PF" },
  { timezone: "Pacific/Guadalcanal", locale: "en-SB" },
  { timezone: "Pacific/Guam", locale: "en-GU" },
  { timezone: "Pacific/Honolulu", locale: "en-US" },
  { timezone: "Pacific/Kiritimati", locale: "en-KI" },
  { timezone: "Pacific/Kosrae", locale: "en-FM" },
  { timezone: "Pacific/Kwajalein", locale: "en-MH" },
  { timezone: "Pacific/Majuro", locale: "en-MH" },
  { timezone: "Pacific/Marquesas", locale: "fr-PF" },
  { timezone: "Pacific/Midway", locale: "en-UM" },
  { timezone: "Pacific/Nauru", locale: "en-NR" },
  { timezone: "Pacific/Niue", locale: "en-NU" },
  { timezone: "Pacific/Norfolk", locale: "en-NF" },
  { timezone: "Pacific/Noumea", locale: "fr-NC" },
  { timezone: "Pacific/Pago_Pago", locale: "en-AS" },
  { timezone: "Pacific/Palau", locale: "en-PW" },
  { timezone: "Pacific/Pitcairn", locale: "en-PN" },
  { timezone: "Pacific/Ponape", locale: "en-FM" },
  { timezone: "Pacific/Port_Moresby", locale: "en-PG" },
  { timezone: "Pacific/Rarotonga", locale: "en-CK" },
  { timezone: "Pacific/Saipan", locale: "en-MP" },
  { timezone: "Pacific/Tahiti", locale: "fr-PF" },
  { timezone: "Pacific/Tarawa", locale: "en-KI" },
  { timezone: "Pacific/Tongatapu", locale: "to-TO" },
  { timezone: "Pacific/Truk", locale: "en-FM" },
  { timezone: "Pacific/Wake", locale: "en-UM" },
  { timezone: "Pacific/Wallis", locale: "fr-WF" },
];
