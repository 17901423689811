import i18nAltai from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import toTitleCaseString from "src/utils/toTitleCaseString";

//English
import account_en from "./locales/en/account.json";
import artistRoster_en from "./locales/en/artistRoster.json";
import channels_en from "./locales/en/channels.json";
import common_en from "./locales/en/common.json";
import communityCasting_en from "./locales/en/communityCasting.json";
import directory_en from "./locales/en/directory.json";
import emails_en from "./locales/en/emails.json";
import godMode_en from "./locales/en/godMode.json";
import lists_en from "./locales/en/lists.json";
import profile_en from "./locales/en/profile.json";
import projects_en from "./locales/en/projects.json";
import registration_en from "./locales/en/registration.json";
import roles_en from "./locales/en/roles.json";
import sessions_en from "./locales/en/sessions.json";
import statusReport_en from "./locales/en/statusReport.json";
import validations_en from "./locales/en/validations.json";

//English AU
import account_enau from "./locales/en_AU/account.json";
import artistRoster_enau from "./locales/en_AU/artistRoster.json";
import channels_enau from "./locales/en_AU/channels.json";
import common_enau from "./locales/en_AU/common.json";
import communityCasting_enau from "./locales/en_AU/communityCasting.json";
import directory_enau from "./locales/en_AU/directory.json";
import emails_enau from "./locales/en_AU/emails.json";
import godMode_enau from "./locales/en_AU/godMode.json";
import lists_enau from "./locales/en_AU/lists.json";
import profile_enau from "./locales/en_AU/profile.json";
import projects_enau from "./locales/en_AU/projects.json";
import registration_enau from "./locales/en_AU/registration.json";
import roles_enau from "./locales/en_AU/roles.json";
import sessions_enau from "./locales/en_AU/sessions.json";
import statusReport_enau from "./locales/en_AU/statusReport.json";
import validations_enau from "./locales/en_AU/validations.json";

//Mexican Spanish
import account_esmx from "./locales/es_MX/account.json";
import artistRoster_esmx from "./locales/es_MX/artistRoster.json";
import channels_esmx from "./locales/es_MX/channels.json";
import common_esmx from "./locales/es_MX/common.json";
import communityCasting_esmx from "./locales/es_MX/communityCasting.json";
import directory_esmx from "./locales/es_MX/directory.json";
import emails_esmx from "./locales/es_MX/emails.json";
import godMode_esmx from "./locales/es_MX/godMode.json";
import lists_esmx from "./locales/es_MX/lists.json";
import profile_esmx from "./locales/es_MX/profile.json";
import projects_esmx from "./locales/es_MX/projects.json";
import registration_esmx from "./locales/es_MX/registration.json";
import roles_esmx from "./locales/es_MX/roles.json";
import sessions_esmx from "./locales/es_MX/sessions.json";
import statusReport_esmx from "./locales/es_MX/statusReport.json";
import validations_esmx from "./locales/es_MX/validations.json";
import { toLocaleForTimezone } from "src/utils/toLocaleForTimezone";

i18nAltai
  .use(
    resourcesToBackend({
      en: {
        account: account_en,
        artistRoster: artistRoster_en,
        channels: channels_en,
        common: common_en,
        communityCasting: communityCasting_en,
        directory: directory_en,
        emails: emails_en,
        godMode: godMode_en,
        lists: lists_en,
        profile: profile_en,
        projects: projects_en,
        registration: registration_en,
        roles: roles_en,
        sessions: sessions_en,
        statusReport: statusReport_en,
        validations: validations_en,
      },
      "en-AU": {
        account: account_enau,
        artistRoster: artistRoster_enau,
        channels: channels_enau,
        common: common_enau,
        communityCasting: communityCasting_enau,
        directory: directory_enau,
        emails: emails_enau,
        godMode: godMode_enau,
        lists: lists_enau,
        profile: profile_enau,
        projects: projects_enau,
        registration: registration_enau,
        roles: roles_enau,
        sessions: sessions_enau,
        statusReport: statusReport_enau,
        validations: validations_enau,
      },
      "es-MX": {
        account: account_esmx,
        artistRoster: artistRoster_esmx,
        channels: channels_esmx,
        common: common_esmx,
        communityCasting: communityCasting_esmx,
        directory: directory_esmx,
        emails: emails_esmx,
        godMode: godMode_esmx,
        lists: lists_esmx,
        profile: profile_esmx,
        projects: projects_esmx,
        registration: registration_esmx,
        roles: roles_esmx,
        sessions: sessions_esmx,
        statusReport: statusReport_esmx,
        validations: validations_esmx,
      },
    })
  )
  .use(LanguageDetector)
  .use(initReactI18next);

if (!i18nAltai.isInitialized) {
  i18nAltai.init({
    debug: true,
    fallbackLng: "en",
    partialBundledLanguages: true,
    initImmediate: false,
    resources: {},
    ns: [],
    interpolation: { escapeValue: false },
  });
}

i18nAltai.services.formatter?.add("capitaliseFirst", (value): string => {
  return toTitleCaseString(value) ?? "";
});

i18nAltai.services.formatter?.add(
  "shortEnglishTimeZone",
  (value, lng): string => {
    return Intl.DateTimeFormat(toLocaleForTimezone(value, lng), {
      timeZone: value,
      timeZoneName: "short",
    })
      .format(Date.now())
      .split(" ")[1];
  }
);

export default i18nAltai;
