import { TimezoneAndManagingLocales } from "src/constants/timeZoneLocales";

export const toLocaleForTimezone = (
  timezone: string,
  currentLocale: string | undefined
): string => {
  // Unfortunately, due to Intl.DateTimeFormat not returning the short
  // locale correctly for Mexican timezones when using "es-MX", we cannot use
  // the currentLocale to fetch it.

  // if (currentLocale && currentLocale.length > 0 && currentLocale !== "es-MX") {
  //   const locale = new Intl.Locale(currentLocale);
  //   //@ts-ignore
  //   const currentLocalesTimezones = locale.getTimeZones();

  //   if (currentLocalesTimezones && Array.isArray(currentLocalesTimezones)) {
  //     var timezonesArray = [...currentLocalesTimezones];

  //     if (timezonesArray.includes(timezone)) {
  //       return currentLocale;
  //     }
  //   }
  // }

  const timezoneAndLocale = TimezoneAndManagingLocales.find(
    (t) => t.timezone === timezone
  );

  return timezoneAndLocale?.locale ?? currentLocale ?? "en-AU";
};
